var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-overlay',{attrs:{"show":_vm.load}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, reset }){return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.returnData)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[(_vm.data.chunk)?_c('div',_vm._l((_vm.data.data),function(row,index){return _c('b-row',{key:index},[(row['head'])?_c('div',{class:row['head']['title'] ? 'hr-line' : ''}):_vm._e(),(_vm.checkHead(row))?_c('b-col',{staticClass:"mb-4 mt-4",attrs:{"lg":row['head']['lg'] || 6,"sm":row['head']['sm'] || 12}},[_c('h5',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(row['head']['title'] ? (row['head']['title'].search('.') !== -1 ? _vm.$t(row['head']['title']) : row['head']['title']) : ''))])]):_vm._e(),(row['items'].length > 0)?_c('b-row',_vm._l((row['items']),function(col,i){return _c('b-col',{key:i,attrs:{"lg":col['lg'] || 6,"sm":col['sm'] || 12}},[_c(col['type'] === 'Checkbox' ? 'CheckboxField' : col['type'],{tag:"component",attrs:{"prop-data":Object.assign(col, {
                                           value: _vm.getValue(_vm.data, row, col),
                                           id: typeof col['title'] !== 'undefined' ? col['title'] : col['name'],
                                           label: col['label'] ? (col['label'].search('.') !== -1 ? _vm.$t(col['label']) : col['label']) : '',
                                           onIf: typeof col['onIf'] !== 'undefined' ? col['onIf'] : true
                                        }),"disabled":true},on:{"returned-value":function($event){return _vm.setData(col, $event)},"picker-instance":function($event){return _vm.$emit('picker-instance', $event)},"field-manipulated":function($event){return _vm.$emit('field-manipulated', $event)}}}),(col['note'])?_c('small',[_vm._v(_vm._s(_vm.$t(col['note'])))]):_vm._e()],1)}),1):_vm._e()],1)}),1):_c('b-row',_vm._l((_vm.data.data),function(col,index){return _c('b-col',{key:index,attrs:{"lg":col['lg'] || 6,"sm":col['sm'] || 12}},[_c(col['type'] === 'Checkbox' ? 'CheckboxField' : col['type'],{key:typeof col['key'] !== 'undefined' ? col['key'] : 1,tag:"component",attrs:{"prop-data":Object.assign(col, {
                                           value: _vm.getValue(_vm.data, '', col),
                                           id: typeof col['title'] !== 'undefined' ? col['title'] : col['name'],
                                           label: col['label'] ? (col['label'].search('.') !== -1 ? _vm.$t(col['label']) : col['label']) : '',
                                           onIf: typeof col['onIf'] !== 'undefined' ? col['onIf'] : true
                                        })},on:{"returned-value":function($event){return _vm.setData(col, $event)},"picker-instance":function($event){return _vm.$emit('picker-instance', $event)},"field-manipulated":function($event){return _vm.$emit('field-manipulated', $event)}}})],1)}),1),_c('b-row',{staticClass:"ml-2 mt-4 d-flex",class:_vm.data['buttonContainerClass'] || 'justify-content-center'},[_c('b-col',{class:_vm.data['buttonColCustomClass'],attrs:{"lg":_vm.data['buttonCol'] || 4}},[_vm._t("submitButton",function(){return [_c('b-button',{class:_vm.data['buttonClass'],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.data['buttonLocale'] ? _vm.$t(_vm.data['buttonLocale']) : _vm.data['buttonText'])+" ")])]},{"data":_vm.data}),(_vm.data['hasCancelButton'])?_vm._t("cancelButton",function(){return [_vm._v("   "),_c('b-button',{class:_vm.data['buttonCancelClass'],attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$bvModal.hide(_vm.modalId || 'modal-4')}}},[_vm._v(" "+_vm._s(_vm.$t('globalTrans.cancel'))+" ")])]}):_vm._e()],2)],1)],1)]}}],null,true)}),_c('pre',[_vm._v(_vm._s(_vm.errors))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }